import {CATALOG_AXIOS} from './backend-config';

export default {
    createBanner(serviceData) {
        const formData = new FormData();
        formData.append('file', serviceData.bannerImage);
        formData.append('deeplink', serviceData.deeplink);
        formData.append('priority', serviceData.priority);
        return CATALOG_AXIOS.post(`/banner`,
            formData
        );
    },
    editBannerPriority(id, priority) {
        const params = new URLSearchParams();
        params.append('priority', priority);
        return CATALOG_AXIOS.put(`/banner/${id}/priority`, params);
    },
    deleteBanner(id) {
        return CATALOG_AXIOS.delete(`/banner/${id}`);
    },
    getBanners(offset, limit) {
        return CATALOG_AXIOS.get('/banner', {
            params: {
                offset,
                limit
            }
        });
    }

}
