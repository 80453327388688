import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './components/App';
import router from './router';
import {BootstrapVue,BootstrapVueIcons} from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import store from '@/store';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import CodeInput from "vue-verification-code-input";

Vue.config.productionTip = false;
Vue.prototype.$subapp = window.__POWERED_BY_QIANKUN__;
let instance = null;

// Bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.component('phone-number-input', VuePhoneNumberInput);
Vue.component('code-input', CodeInput);


function render(props = {}) {
  const { container } = props;
  instance = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
  });
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log('%c%s', 'color: green;', 'vue3.0 app bootstraped');
}

export async function mount(props) {
  render(props);
}

export async function unmount() {
  instance = null;
}