import {AXIOS, CATALOG_AXIOS} from './backend-config';

export default {
    getCatalogIntegrationBackendVersion() {
        return AXIOS.get('application-info/versions')
    },
    getCatalogServerBackendVersion() {
        return CATALOG_AXIOS.get('application-info/versions')
    }

}