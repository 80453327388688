<template>
  <div>
    <router-link v-if="isAdmin" class="btn btn-primary mb-2" to="/banner/create">Создать баннер</router-link>
    <b-table
      hover
      :items="getBannerDataTable"
      :fields="fields"
      ref="banner_table"
      :per-page="bannerList.perPage"
      class="table-bordered table-hover"
      show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Пользователи отсутствуют</h6>
      </template>
      <template v-slot:cell(index)="row">
        {{ bannerList.perPage * (bannerList.currentPage - 1) + row.index + 1 }}
      </template>
      <template v-slot:cell(id)="row">
        ID: {{ row.item.id }}
      </template>
      <template v-slot:cell(deeplink)="row">{{ row.item.deeplink }}</template>
      <template v-slot:cell(priority)="row">{{ row.item.priority }}</template>
      <template v-slot:cell(image)="row">
        <img class="img-fluid mx-auto d-block" v-if="row.item.imagePaths" :src="row.item.imagePaths" alt="banner image" />
      </template>
      <template v-slot:cell(interaction)="row">
        <b-button v-if="isAdmin"
          variant="primary"
          title="Редактировать приоритет"
          class="mr-1"
          @click="editBannerPriority(row.item)"
        >
          <b-icon-pencil font-scale="0.75"></b-icon-pencil>
        </b-button>
        <b-button v-if="isAdmin"
          variant="primary"
          title="Удалить"
          @click="deleteBanner(row.item.id)"
        >
          <b-icon-trash font-scale="0.75"></b-icon-trash>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
          v-model="bannerList.perPage"
          class="text-light border-secondary bg-secondary"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
        v-model="bannerList.currentPage"
        class="my-0 ml-auto"
        :total-rows="bannerList.totalCount"
        :per-page="bannerList.perPage"
        aria-controls="template-table"
        @input="getBannerDataTable"
      ></b-pagination>
    </div>
    <b-modal
      ref="priority-modal"
      title="Редактирование приоритета"
      cancel-title="Отмена"
      @ok="onEditBannerPriorityConfirm"
    >
      <b-form-group label="Приоритет:" label-size="lg">
        <b-form-input v-model.number="editedPriority" type="number"
          placeholder="Введите новый приоритет для баннера"></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
  import banner from '@/modules/banner';
  import {BIcon, BIconTrash} from 'bootstrap-vue';
  import {mapGetters} from "vuex";

  export default {
    name: 'banner-list',
    components: {
      'b-icon-trash': BIconTrash,
    },
    data() {
      return {
        fields: [
          {
            key: 'index', label: '№'
          },
          {
            key: 'id', label: 'ID'
          },
          {
            key: 'deeplink', label: 'Ссылка'
          },
          {
            key: 'priority', label: 'Приоритет'
          },
          {
            key: 'image', label: 'Изображение'
          },
          {
            key: 'interaction', label: 'Взаимодействие'
          }
        ],
        bannerList: {
          totalCount: 0,
          perPage: 20,
          currentPage: 1
        },
        editedBannerId: 0,
        editedPriority: 0,
      };
    },
    computed: {
      ...mapGetters([
        "isAdmin"
      ])
    },
    methods: {
      getBannerDataTable(context) {
        return banner.getBanners((context.currentPage - 1) * context.perPage,
                                 context.perPage)
                     .catch((error) => {
                       let errorMessage;
                       if (error.isAxiosError) {
                         errorMessage = `Ошибка при запросе списка баннеров (код ${error.response?.status})`;
                       } else {
                         errorMessage = 'Неизвестная ошибка при запросе списка баннеров';
                       }
                       this.$bvToast.toast(errorMessage, {
                         title: 'Ошибка',
                         autoHideDelay: 5000,
                         appendToast: true
                       });

                       throw error;
                     })
                     .then(resp => {
                       if (Array.isArray(resp.data)) {
                         this.bannerList.totalCount = resp.data.length;
                         return resp.data;
                       } else {
                         this.bannerList.totalCount = resp.data.totalCount;
                         return resp.data.results;
                       }
                     });
      },
      editBannerPriority(item) {
        // TODO: достать приоритет из модалки
        this.editedPriority = item.priority ?? 0;
        this.editedBannerId = item.id;

        this.$refs["priority-modal"].show();
      },
      onEditBannerPriorityConfirm() {
        banner.editBannerPriority(this.editedBannerId, this.editedPriority)
              .then(() => {
                this.$refs.banner_table.refresh();
              });
      },
      deleteBanner(id) {
        this.$bvModal
            .msgBoxConfirm('Вы уверены, что хотите удалить баннер?', {
              title: 'Удаление',
              titleTag: 'h6',
              okVariant: 'danger',
              okTitle: 'Удалить',
              cancelTitle: 'Отмена',
              cancelVariant: 'outline-secondary',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
            .then(value => {
              if (value) {
                banner.deleteBanner(id)
                      .catch(error => {
                        let errorMessage;
                        if (error.isAxiosError) {
                          errorMessage = `Ошибка при удалении параметра (код ${error.response?.status})`;
                        } else {
                          errorMessage = 'Неизвестная ошибка при удалении параметра';
                        }
                        this.$bvToast.toast(errorMessage, {
                          title: 'Ошибка',
                          variant: 'danger',
                          autoHideDelay: 5000,
                          appendToast: true
                        });
                      })
                      .finally(() => {
                        this.$refs.banner_table.refresh();
                      });
              }
            });
      },
    }
  }
</script>
<style lang="scss" scoped>
  img {
    max-width: 25vw;
  }
</style>
