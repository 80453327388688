import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import ImportCatalog from './views/ImportCatalog.vue';
import ImportReceipt from './views/ImportReceipt.vue';
import BannerList from './views/BannerList.vue';
import CreateBanner from './views/CreateBanner.vue';
import store from './store';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base:  window.__POWERED_BY_QIANKUN__ ? '/service/catalog/' : '/',
    routes: [
        {path: '/catalog', component: ImportCatalog, meta: {title: 'Импорт каталога'}},
        {path: '/receipt', component: ImportReceipt, meta: {title: 'Импорт поступлений'}},
        {path: '/banner', component: BannerList, meta: {title: 'Список баннеров'}},
        {path: '/banner/create', component: CreateBanner, meta: {title: 'Создание баннеров'}},
        {path: '/login', component: Login, meta: {title: 'Вход'}},
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }

    const directingPath = to.path.endsWith('/') ? to.path.slice(0, -1) : to.path;

    if (!store.getters.isAuthorized && '/login' !== directingPath) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === directingPath) {
        return next('/catalog');
    }
    if (!store.getters.isAdmin && '/banner' === directingPath) {
        return next(from.path);
    }
    return next();
});

export default router;
